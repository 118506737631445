<template>
    <!-- Page: pages/login -->
    <div class="container">
        <div class="reset-pw-container">
            <h1>Reset Password</h1>

            <preloader v-if="loading"/>
            <form action="#reset-password" v-on:submit.prevent="reset" v-else>
                <div class="field">
                    <label class="label" for="rp-new">New Password</label>
                    <div class="control">
                        <input class="input" type="password" placeholder="New Password" id="rp-new"
                               v-model="newPassword">
                    </div>
                    <PasswordRequirements :password="newPassword"/>
                </div>
                <div class="field">
                    <label class="label" for="rp-new-confirm">Confirm Password</label>
                    <div class="control">
                        <input class="input" type="password" placeholder="Confirm Password" id="rp-new-confirm"
                               v-model="newPasswordConfirm">
                    </div>
                </div>

                <div>
                    <input type="submit" class="button is-primary" value="Confirm">
                </div>
                <div v-if="resetError" class="error-message" v-html="resetError"></div>
            </form>

        </div>
    </div>
    <!-- End Page: pages/login -->
</template>

<script>
    import { ref, watchEffect } from "vue";
    import PasswordRequirements from "./password-requirements";
    import { useRouter } from "vue-router";
    import RequestHandler from "@/handler/RequestHandler";
    import { useStore } from "vuex";
    import { ACTION_TYPES } from "@/constants";
    import Preloader from "./preloader";
    import useUser from "@/helpers/useUser";

    export default {
        name: 'login-page',
        components: {
            Preloader,
            PasswordRequirements
        },
        setup() {
            const router = useRouter();
            const store = useStore();
            const loading = ref(false);
            const resetError = ref('');
            const newPassword = ref('');
            const newPasswordConfirm = ref('');

            const { redirectIfLoggedIn, userLoaded } = useUser();
            watchEffect(() => {
                redirectIfLoggedIn(router);
                if (userLoaded.value) {
                    loading.value = false;
                }
            });

            const reset = () => {
                if (newPassword.value !== newPasswordConfirm.value) {
                    resetError.value = '"Confirm Password" doesn\'t match "New Password"';
                    return;
                }
                loading.value = true;
                resetError.value = '';
                RequestHandler.resetNewPassword({
                    newPassword: newPassword.value,
                    token: router.currentRoute.value.params.token,
                }, store.getters.csrf)
                    .then((response) => {
                        store.dispatch(ACTION_TYPES.signIn, {
                            login: response.login,
                            password: newPassword.value,
                        }).catch(() => {
                            resetError.value = 'Reset completed, please sign in';
                            loading.value = false;
                        });
                    })
                    .catch((error) => {
                        resetError.value = (error.response.data && error.response.data.error)
                            ? error.response.data.error.replaceAll('ERROR:', '<br>●')
                            : 'Something went wrong, please try again';
                        loading.value = false;
                    });
            };

            return {
                loading,
                reset,
                resetError,
                newPassword,
                newPasswordConfirm,
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";

    h1 {
        margin-bottom: 30px;
    }

    .reset-pw-container {
        max-width: 600px;
        margin: 0 auto;
        @media screen and (max-width: 650px) {
            margin: 0 15px;
        }
    }

    .error-message {
        margin-top: 20px;
        font-style: italic;
        color: $red;
    }

    .input {
        border: 2px solid $blue--dark;
    }

    .button {
        margin-top: 10px;
    }

    .password-details {
        font-style: italic;
        font-size: 12px;
        color: #5a6171;
        padding-top: 4px;
    }
</style>
