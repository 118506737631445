<template>
    <!-- Component: atoms/password-requirements -->
    <p class="password-details">
        <span v-if="!password || password.length < 10">At least 10 characters long.</span>
        <span v-if="!password || password.toUpperCase() === password"> At least one lower case letter.</span>
        <span v-if="!password || password.toLowerCase() === password"> At least one upper case letter.</span>
    </p>
    <!-- End Component: atoms/password-requirements -->
</template>

<script>
    export default {
        name: 'PasswordRequirements',
        props: ['password'],
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

    .password-details {
        font-style: italic;
        font-size: 12px;
        color: #5a6171;
        padding-top: 4px;
    }
</style>
